html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

@import 'assets/styles/themes';
@import 'assets/styles/variables';
@import 'assets/styles/modify-material';
@import 'assets/styles/fonts';


body {
  font-family: 'Roboto', sans-serif !important;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  color: var(--md-sys-color-text-black);

  /* total width */
  ::-webkit-scrollbar {
    background-color: var(--md-sys-color-main-input-field);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;
    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: var(--md-sys-color-main-input-field);
    border-radius: 1rem;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: var(--md-sys-color-main-inactive);
    border-radius: 1rem;
    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $mobility-pwa-primary: mat.define-palette(mat.$indigo-palette);
// $mobility-pwa-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $mobility-pwa-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $mobility-pwa-theme: mat.define-light-theme((
//   color: (
//     primary: $mobility-pwa-primary,
//     accent: $mobility-pwa-accent,
//     warn: $mobility-pwa-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($mobility-pwa-theme);

// /* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

input {
  font-family: inherit;
}
