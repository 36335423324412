@import "components/drag-scroll/drag-scroll.scss";
@import "components/accordion/accordion.scss";
@import "components/dialog/index.scss";

body {
  .events-map-cluster {
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 100%;
    color: var(--md-sys-color-main-sidebar);
    font-size: 1rem;
    font-weight: 900;
    opacity: 0.8;
    background: radial-gradient(circle, #ffffff 50%, #393fc5 100%);
  }

  .mat-card {
    &:not([class*="mat-elevation-z"]) {
      box-shadow: none;
    }
  }

  .mat-button {
    .mat-button-wrapper {
      svg {
        margin-right: 0.5rem;
      }

      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  // .mat-icon-button {
  //   border-radius: 25%;
  //   .mat-button-wrapper {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }
  // }

  .mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0;
  }

  .normalize-dialog {
    height: 100%;

    .mat-dialog-container {
      padding: 0;
      overflow: hidden;
    }

    &-overflow {
      .mat-dialog-container {
        height: auto;
        padding: 0;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  // Переопределяем стили для степпера
  .stepper {
    .mat-horizontal-content-container {
      padding: 1.125rem;
    }

    .mat-horizontal-stepper-header-container {
      width: 14rem;
      margin: 0 auto 0;
    }

    .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
      top: 24px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
      top: 24px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
    .mat-vertical-stepper-header {
      padding: 12px;
    }

    .mat-stepper-label-position-bottom
      .mat-horizontal-stepper-header
      .mat-step-label {
      display: none;
    }
  }

  .mat-spinner-invert {
    circle {
      stroke: var(--md-sys-color-main-white);
    }
  }

  .container .mat-drawer-container {
    background-color: var(--md-sys-color-main-web-background);
  }


 .container .mat-expansion-panel {
    background-color: var(--md-sys-color-main-widget-bg);
  }

  mat-checkbox.mat-checkbox-full-width {
    .mat-checkbox-layout {
      width: 100%;
      .mat-checkbox-label {
        width: 100%;
      }
    }
  }

  .form-field-hide-validation {
    .mat-form-field-wrapper {
      padding: 0;
    }
  }

  .backdrop-transparent {
    opacity: 0;
  }
}

.page-login {
  .mat-form-field-label {
    color: #ffffff;
  }
  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: #e0e0e0;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #ffffff00;
  }
}

.warnMessage {
  background-color:  #fbe9e7;
  color:   #e64a19;
}
.accentMessage {
  background-color:  #fbe9e7;
  color:   #e64a19;
}

.okMessage {
  background-color:  #e8f5e9;
  color:  #388e3c;
}

.mat-dialog-container {
  max-height: 100vh!important;
}
