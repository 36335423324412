.table-dealer {
  .mat-header-cell {
    // background-color: var(--surfaceVariant);
    background-color: var(--md-sys-color-main-input-field);

    font-weight: 600;

    &:first-of-type {
      border-radius: 1rem 0 0 0;
    }
    &:last-of-type {
      border-radius: 0 1rem 0 0;
    }
  }

  .mat-header-cell,
  .mat-cell {
    border-right: 1px solid var(--md-sys-color-main-divider) !important;
    border-bottom-color: var(--md-sys-color-main-divider) !important;
    white-space: nowrap;
    padding-right: 0.35rem;
    padding-left: 0.35rem;
    &:last-of-type {
      border-right: none;
    }
  }
}
