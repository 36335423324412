body {
  .mat-dialog-container {
    border-radius: 1.25rem;
  }

  .dialog {
    .mat-dialog-title {
      margin: -24px -24px 16px -24px;
      padding: 1rem 2rem;
      font-size: 1.5rem;
      font-weight: 600;
      background: linear-gradient(
        180deg,
        #d8e1ea 0%,
        rgba(216, 225, 234, 0.15) 100%
      );

      display: flex;
      align-items: center;
      gap: 1rem;
      color: var(--md-sys-color-main-sidebar);

      mat-icon {
        margin-left: auto;
      }
    }

    .mat-dialog-content {
      display: flex;
      flex-direction: column;
    }

    .mat-dialog-actions {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
