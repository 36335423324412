mat-accordion.coloring-accordion {
  gap: .5rem;
  display: flex;
  flex-direction: column;

  .mat-expansion-panel {
    box-shadow: none;
  }

  .mat-expansion-panel-header {
    max-height: 2.5rem;
  }

  .mat-expansion-panel.panel-header-color {
    border-radius: 0.625rem;

    &--pink {
      .mat-expansion-panel-header {
        background-color: var(--md-sys-color-accordion-colors-pink-light);
      }
    }

    &--green {
      .mat-expansion-panel-header {
        color: var(--md-sys-color-accordion-colors-green-dark);
        stroke: var(--md-sys-color-accordion-colors-green-dark);
        background-color: var(--md-sys-color-accordion-colors-green-light);
      }
    }

    &--tertiary {
      .mat-expansion-panel-header {
        color: var(--md-sys-color-main-sidebar);
        stroke: var(--md-sys-color-text-main-tes);
        background-color: var(--md-sys-color-main-input-field);
      }
    }

    &--green {
      .mat-expansion-panel-header {
        color: var(--md-sys-color-accordion-colors-green-dark);
        stroke: var(--md-sys-color-accordion-colors-green-dark);
        background-color: var(--md-sys-color-accordion-colors-green-light);
      }
    }

    &--orange {
      .mat-expansion-panel-header {
        color: var(--md-sys-color-accordion-colors-yellow-dark);
        stroke: var(--md-sys-color-accordion-colors-yellow-dark);
        background-color: var(--md-sys-color-accordion-colors-yellow-light);
      }
    }

    &--blue {
      .mat-expansion-panel-header {
        color: var(--md-sys-color-accordion-colors-blue-dark);
        stroke: var(--md-sys-color-accordion-colors-blue-dark);
        background-color: var(--md-sys-color-accordion-colors-blue-light);
      }
    }

    &--pink {
      .mat-expansion-panel-header {
        color: var(--md-sys-color-accordion-colors-pink-dark);
        stroke: var(--md-sys-color-accordion-colors-pink-dark);
        background-color: var(--md-sys-color-accordion-colors-pink-light);
      }
    }
  }
}
