  $md-source: #16ADBE;
  /* primary */
  $md-ref-palette-primary0: #000000;
  $md-ref-palette-primary10: #001f24;
  $md-ref-palette-primary20: #00363d;
  $md-ref-palette-primary25: #00424a;
  $md-ref-palette-primary30: #004f57;
  $md-ref-palette-primary35: #005b65;
  $md-ref-palette-primary40: #006874;
  $md-ref-palette-primary50: #008491;
  $md-ref-palette-primary60: #00a0b0;
  $md-ref-palette-primary70: #22bcce;
  $md-ref-palette-primary80: #4fd8ea;
  $md-ref-palette-primary90: #95f1ff;
  $md-ref-palette-primary95: #cff8ff;
  $md-ref-palette-primary98: #edfcff;
  $md-ref-palette-primary99: #f6feff;
  $md-ref-palette-primary100: #ffffff;
  /* secondary */
  $md-ref-palette-secondary0: #000000;
  $md-ref-palette-secondary10: #001d33;
  $md-ref-palette-secondary20: #003354;
  $md-ref-palette-secondary25: #003e65;
  $md-ref-palette-secondary30: #004a77;
  $md-ref-palette-secondary35: #005689;
  $md-ref-palette-secondary40: #00639c;
  $md-ref-palette-secondary50: #257cbc;
  $md-ref-palette-secondary60: #4896d7;
  $md-ref-palette-secondary70: #66b1f4;
  $md-ref-palette-secondary80: #98cbff;
  $md-ref-palette-secondary90: #cee5ff;
  $md-ref-palette-secondary95: #e8f2ff;
  $md-ref-palette-secondary98: #f7f9ff;
  $md-ref-palette-secondary99: #fcfcff;
  $md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  $md-ref-palette-tertiary0: #000000;
  $md-ref-palette-tertiary10: #001c3b;
  $md-ref-palette-tertiary20: #003060;
  $md-ref-palette-tertiary25: #003b73;
  $md-ref-palette-tertiary30: #004787;
  $md-ref-palette-tertiary35: #00529c;
  $md-ref-palette-tertiary40: #005eb1;
  $md-ref-palette-tertiary50: #2a78d0;
  $md-ref-palette-tertiary60: #4c92ec;
  $md-ref-palette-tertiary70: #74adff;
  $md-ref-palette-tertiary80: #a6c8ff;
  $md-ref-palette-tertiary90: #d5e3ff;
  $md-ref-palette-tertiary95: #ebf1ff;
  $md-ref-palette-tertiary98: #f9f9ff;
  $md-ref-palette-tertiary99: #fdfbff;
  $md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  $md-ref-palette-neutral0: #000000;
  $md-ref-palette-neutral10: #001e2c;
  $md-ref-palette-neutral20: #003548;
  $md-ref-palette-neutral25: #004058;
  $md-ref-palette-neutral30: #004d67;
  $md-ref-palette-neutral35: #005978;
  $md-ref-palette-neutral40: #006688;
  $md-ref-palette-neutral50: #0080aa;
  $md-ref-palette-neutral60: #1f9bcb;
  $md-ref-palette-neutral70: #49b6e8;
  $md-ref-palette-neutral80: #76d1ff;
  $md-ref-palette-neutral90: #c2e8ff;
  $md-ref-palette-neutral95: #e2f3ff;
  $md-ref-palette-neutral98: #f5faff;
  $md-ref-palette-neutral99: #fbfcff;
  $md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  $md-ref-palette-neutral-variant0: #000000;
  $md-ref-palette-neutral-variant10: #141d1f;
  $md-ref-palette-neutral-variant20: #293234;
  $md-ref-palette-neutral-variant25: #343d3f;
  $md-ref-palette-neutral-variant30: #3f484a;
  $md-ref-palette-neutral-variant35: #4b5456;
  $md-ref-palette-neutral-variant40: #576062;
  $md-ref-palette-neutral-variant50: #6f797a;
  $md-ref-palette-neutral-variant60: #899294;
  $md-ref-palette-neutral-variant70: #a3adaf;
  $md-ref-palette-neutral-variant80: #bfc8ca;
  $md-ref-palette-neutral-variant90: #dbe4e6;
  $md-ref-palette-neutral-variant95: #e9f2f4;
  $md-ref-palette-neutral-variant98: #f1fbfd;
  $md-ref-palette-neutral-variant99: #f6feff;
  $md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  $md-ref-palette-error0: #000000;
  $md-ref-palette-error10: #410002;
  $md-ref-palette-error20: #690005;
  $md-ref-palette-error25: #7e0007;
  $md-ref-palette-error30: #93000a;
  $md-ref-palette-error35: #a80710;
  $md-ref-palette-error40: #ba1a1a;
  $md-ref-palette-error50: #de3730;
  $md-ref-palette-error60: #ff5449;
  $md-ref-palette-error70: #ff897d;
  $md-ref-palette-error80: #ffb4ab;
  $md-ref-palette-error90: #ffdad6;
  $md-ref-palette-error95: #ffedea;
  $md-ref-palette-error98: #fff8f7;
  $md-ref-palette-error99: #fffbff;
  $md-ref-palette-error100: #ffffff;

  /* label - small */
  $md-sys-typescale-label-small-font-family-name: Roboto;
  $md-sys-typescale-label-small-font-family-style: Medium;
  $md-sys-typescale-label-small-font-weight: 500px;
  $md-sys-typescale-label-small-font-size: 11px;
  $md-sys-typescale-label-small-letter-spacing: 0.50px;
  $md-sys-typescale-label-small-line-height: 16px;
  /* label - medium */
  $md-sys-typescale-label-medium-font-family-name: Roboto;
  $md-sys-typescale-label-medium-font-family-style: Medium;
  $md-sys-typescale-label-medium-font-weight: 500px;
  $md-sys-typescale-label-medium-font-size: 12px;
  $md-sys-typescale-label-medium-letter-spacing: 0.50px;
  $md-sys-typescale-label-medium-line-height: 16px;
  /* label - large */
  $md-sys-typescale-label-large-font-family-name: Roboto;
  $md-sys-typescale-label-large-font-family-style: Medium;
  $md-sys-typescale-label-large-font-weight: 500px;
  $md-sys-typescale-label-large-font-size: 14px;
  $md-sys-typescale-label-large-letter-spacing: 0.10px;
  $md-sys-typescale-label-large-line-height: 20px;
  /* body - small */
  $md-sys-typescale-body-small-font-family-name: Roboto;
  $md-sys-typescale-body-small-font-family-style: Regular;
  $md-sys-typescale-body-small-font-weight: 400px;
  $md-sys-typescale-body-small-font-size: 12px;
  $md-sys-typescale-body-small-letter-spacing: 0.40px;
  $md-sys-typescale-body-small-line-height: 16px;
  /* body - medium */
  $md-sys-typescale-body-medium-font-family-name: Roboto;
  $md-sys-typescale-body-medium-font-family-style: Regular;
  $md-sys-typescale-body-medium-font-weight: 400px;
  $md-sys-typescale-body-medium-font-size: 14px;
  $md-sys-typescale-body-medium-letter-spacing: 0.25px;
  $md-sys-typescale-body-medium-line-height: 20px;
  /* body - large */
  $md-sys-typescale-body-large-font-family-name: Roboto;
  $md-sys-typescale-body-large-font-family-style: Regular;
  $md-sys-typescale-body-large-font-weight: 400px;
  $md-sys-typescale-body-large-font-size: 16px;
  $md-sys-typescale-body-large-letter-spacing: 0.50px;
  $md-sys-typescale-body-large-line-height: 24px;
  /* headline - small */
  $md-sys-typescale-headline-small-font-family-name: Roboto;
  $md-sys-typescale-headline-small-font-family-style: Regular;
  $md-sys-typescale-headline-small-font-weight: 400px;
  $md-sys-typescale-headline-small-font-size: 24px;
  $md-sys-typescale-headline-small-letter-spacing: 0px;
  $md-sys-typescale-headline-small-line-height: 32px;
  /* headline - medium */
  $md-sys-typescale-headline-medium-font-family-name: Roboto;
  $md-sys-typescale-headline-medium-font-family-style: Regular;
  $md-sys-typescale-headline-medium-font-weight: 400px;
  $md-sys-typescale-headline-medium-font-size: 28px;
  $md-sys-typescale-headline-medium-letter-spacing: 0px;
  $md-sys-typescale-headline-medium-line-height: 36px;
  /* headline - large */
  $md-sys-typescale-headline-large-font-family-name: Roboto;
  $md-sys-typescale-headline-large-font-family-style: Regular;
  $md-sys-typescale-headline-large-font-weight: 400px;
  $md-sys-typescale-headline-large-font-size: 32px;
  $md-sys-typescale-headline-large-letter-spacing: 0px;
  $md-sys-typescale-headline-large-line-height: 40px;
  /* display - small */
  $md-sys-typescale-display-small-font-family-name: Roboto;
  $md-sys-typescale-display-small-font-family-style: Regular;
  $md-sys-typescale-display-small-font-weight: 400px;
  $md-sys-typescale-display-small-font-size: 36px;
  $md-sys-typescale-display-small-letter-spacing: 0px;
  $md-sys-typescale-display-small-line-height: 44px;
  /* display - medium */
  $md-sys-typescale-display-medium-font-family-name: Roboto;
  $md-sys-typescale-display-medium-font-family-style: Regular;
  $md-sys-typescale-display-medium-font-weight: 400px;
  $md-sys-typescale-display-medium-font-size: 45px;
  $md-sys-typescale-display-medium-letter-spacing: 0px;
  $md-sys-typescale-display-medium-line-height: 52px;
  /* display - large */
  $md-sys-typescale-display-large-font-family-name: Roboto;
  $md-sys-typescale-display-large-font-family-style: Regular;
  $md-sys-typescale-display-large-font-weight: 400px;
  $md-sys-typescale-display-large-font-size: 57px;
  $md-sys-typescale-display-large-letter-spacing: -0.25px;
  $md-sys-typescale-display-large-line-height: 64px;
  /* title - small */
  $md-sys-typescale-title-small-font-family-name: Roboto;
  $md-sys-typescale-title-small-font-family-style: Medium;
  $md-sys-typescale-title-small-font-weight: 500px;
  $md-sys-typescale-title-small-font-size: 14px;
  $md-sys-typescale-title-small-letter-spacing: 0.10px;
  $md-sys-typescale-title-small-line-height: 20px;
  /* title - medium */
  $md-sys-typescale-title-medium-font-family-name: Roboto;
  $md-sys-typescale-title-medium-font-family-style: Medium;
  $md-sys-typescale-title-medium-font-weight: 500px;
  $md-sys-typescale-title-medium-font-size: 16px;
  $md-sys-typescale-title-medium-letter-spacing: 0.15px;
  $md-sys-typescale-title-medium-line-height: 24px;
  /* title - large */
  $md-sys-typescale-title-large-font-family-name: Roboto;
  $md-sys-typescale-title-large-font-family-style: Regular;
  $md-sys-typescale-title-large-font-weight: 400px;
  $md-sys-typescale-title-large-font-size: 22px;
  $md-sys-typescale-title-large-letter-spacing: 0px;
  $md-sys-typescale-title-large-line-height: 28px;


  /* light - main */
  $md-sys-color-light-main-sidebar: #343E48;
  $md-sys-color-light-main-sidebar-chosen-point: #48515A;
  $md-sys-color-light-main-icons: #72879E;
  $md-sys-color-light-main-inactive: #CAD2DA;
  $md-sys-color-light-main-input-field: #F2F4F7;
  $md-sys-color-light-main-divider: #E0E0E0;
  $md-sys-color-light-main-widget-bg: #FEFEFE;
  $md-sys-color-light-main-web-background: #F2F4F7;
  $md-sys-color-light-main-accent-activated: #1CD29C;
  $md-sys-color-light-main-accent-inactivated: #FC7D7D;
  $md-sys-color-light-main-white: #FFFFFF;

  /* light - text */
  $md-sys-color-light-text-grey: #9E9E9E;
  $md-sys-color-light-text-black: #232323;
  $md-sys-color-light-text-main-tes: #343E48;

  /* light - accordion colors */
  $md-sys-color-light-accordion-colors-pink-light: #FAF5FF;
  $md-sys-color-light-accordion-colors-pink-dark: #A891BF;
  $md-sys-color-light-accordion-colors-blue-light: #EEF9FF;
  $md-sys-color-light-accordion-colors-blue-dark: #213A8B;
  $md-sys-color-light-accordion-colors-green-light: #F1FFFC;
  $md-sys-color-light-accordion-colors-green-dark: #16ADBE;
  $md-sys-color-light-accordion-colors-grey-light: #F2F4F7;
  $md-sys-color-light-accordion-colors-grey-dark: #323232;
  $md-sys-color-light-accordion-colors-yellow-light: #FCF7EA;
  $md-sys-color-light-accordion-colors-yellow-dark: #CA9715;

  /* light - gradient */
  $md-sys-color-light-gradient-tes-main: linear-gradient(180.02deg, #39434D 5.74%, #0F1923 99.98%);
  $md-sys-color-light-gradient-green: linear-gradient(180deg, #AAFFF0 0%, #00A1B7 100%);
  $md-sys-color-light-gradient-orange: linear-gradient(184.71deg, #FF8580 -51.35%, #FFAE5E 96.2%);
  $md-sys-color-light-gradient-grey: linear-gradient(173.16deg, #96B1CC -46.88%, #C3D8ED 94.64%);

  /* light - drivers styles text */
  $md-sys-color-light-driver-text-danger: #DE2A2A;
  $md-sys-color-light-driver-text-bad: #D69E0F;
  $md-sys-color-light-driver-text-good: #0DA97B;
  $md-sys-color-light-driver-text-perfect: #327DD6;

  /* dark - main */
  $md-sys-color-dark-main-sidebar: #343E48;
  $md-sys-color-dark-main-sidebar-chosen-point: #48515A;
  $md-sys-color-dark-main-icons: #EAEBEC;
  $md-sys-color-dark-main-inactive: #CAD2DA;
  $md-sys-color-dark-main-input-field: #58626B;
  $md-sys-color-dark-main-divider: #83888D;
  $md-sys-color-dark-main-widget-bg: #2C3339;
  $md-sys-color-dark-main-web-background: #000000;
  $md-sys-color-dark-main-accent-activated: #1CD29C;
  $md-sys-color-dark-main-accent-inactivated: #FC7D7D;
  $md-sys-color-dark-main-black: #000000;

  /* dark - text */
  $md-sys-color-dark-text-grey: #E0E0E0;
  $md-sys-color-dark-text-white: #FFFFFF;
  $md-sys-color-dark-text-main-tes: #FBFBFB;

  /* dark - accordion colors */
  $md-sys-color-dark-accordion-colors-pink-light: #FAF5FF;
  $md-sys-color-dark-accordion-colors-pink-dark: #69537E;
  $md-sys-color-dark-accordion-colors-blue-light: #EEF9FF;
  $md-sys-color-dark-accordion-colors-blue-dark: #213A8B;
  $md-sys-color-dark-accordion-colors-green-light: #F1FFFC;
  $md-sys-color-dark-accordion-colors-green-dark: #007A88;
  $md-sys-color-dark-accordion-colors-grey-light: #F2F4F7;
  $md-sys-color-dark-accordion-colors-grey-dark: #323232;
  $md-sys-color-dark-accordion-colors-yellow-light: #FCF7EA;
  $md-sys-color-dark-accordion-colors-yellow-dark: #CA9715;

  /* dark - gradient */
  $md-sys-color-dark-gradient-tes-main: linear-gradient(180.02deg, #39434D 5.74%, #0F1923 99.98%);
  $md-sys-color-dark-gradient-green: linear-gradient(180deg, #AAFFF0 0%, #00A1B7 100%);
  $md-sys-color-dark-gradient-orange: linear-gradient(184.71deg, #FF8580 -51.35%, #FFAE5E 96.2%);
  $md-sys-color-dark-gradient-grey: linear-gradient(173.16deg, #96B1CC -46.88%, #C3D8ED 94.64%);

  /* dark - drivers styles text */
  $md-sys-color-dark-driver-text-danger: #DE2A2A;
  $md-sys-color-dark-driver-text-bad: #D69E0F;
  $md-sys-color-dark-driver-text-good: #1CD29C;
  $md-sys-color-dark-driver-text-perfect: #327DD6;

  /* label - color */
  $md-sys-color-label-green: #16ADBE;
  $md-sys-color-label-dark-grey: #72879E;
  $md-sys-color-label-light-grey: #BFCFE0;
  $md-sys-color-label-blue: #327DD6;
  $md-sys-color-label-yellow: #FFEB7F;
  $md-sys-color-label-pink: #EDDDFC;
  $md-sys-color-label-red: #FC7D7D;

  /* shadow */
  $md-sys-color-shadow: -1px 3px 20px 1px #212C3714;
  $md-sys-color-shadow-button: 2px 2px 19px #343E4836;
