@use 'sass:map';
@use '@angular/material' as mat;
@import "tokens";

body{
  //--primary: #{$md-sys-color-primary-light};
  //--onPrimary: #{$md-sys-color-on-primary-light};
  //--primaryContainer: #{$md-sys-color-primary-container-light};
  //--onPrimaryContainer: #{$md-sys-color-on-primary-container-light};
  //
  //--secondary: #{$md-sys-color-secondary-light};
  //--onSecondary: #{$md-sys-color-on-secondary-light};
  //--secondaryContainer: #{$md-sys-color-secondary-container-light};
  //--onSecondaryContainer: #{$md-sys-color-on-secondary-container-light};
  //
  //--tertiary: #{$md-sys-color-tertiary-light};
  //--onTertiary: #{$md-sys-color-on-tertiary-light};
  //--tertiaryContainer: #{$md-sys-color-tertiary-container-light};
  //--onTertiaryContainer: #{$md-sys-color-on-tertiary-container-light};
  //
  //--error: #{$md-sys-color-error-light};
  //--onError: #{$md-sys-color-error-container-light};
  //--errorContainer: #{$md-sys-color-error-container-light};
  //--onerrorContainer: #{$md-sys-color-on-error-container-light};
  //
  //--background: #{$md-sys-color-background-light};
  //--onBackground: #{$md-sys-color-on-background-light};
  //--surface: #{$md-sys-color-surface-light};
  //--onSurface: #{$md-sys-color-on-surface-light};

  //--outline: #{$md-sys-color-outline-light};
  //--surfaceVariant: #{$md-sys-color-surface-variant-light};
  //--onSurfaceVariant: #{$md-sys-color-on-surface-variant-light};

  //--labelGreen: #{$md-sys-color-label-green};
  //--labelDarkGrey: #{$md-sys-color-label-dark-grey};
  //--labelLightGrey: #{$md-sys-color-label-light-grey};
  //--labelBlue: #{$md-sys-color-label-blue};
  //--labelYellow: #{$md-sys-color-label-yellow};
  //--labelPink: #{$md-sys-color-label-pink};
  //--labelRed: #{$md-sys-color-label-red};

  /* light - main */
  --md-sys-color-main-sidebar: #{$md-sys-color-light-main-sidebar};
  --md-sys-color-main-sidebar-chosen-point: #{$md-sys-color-light-main-sidebar-chosen-point};
  --md-sys-color-main-icons: #{$md-sys-color-light-main-icons};
  --md-sys-color-main-inactive: #{$md-sys-color-light-main-inactive};
  --md-sys-color-main-input-field: #{$md-sys-color-light-main-input-field};
  --md-sys-color-main-divider: #{$md-sys-color-light-main-divider};
  --md-sys-color-main-widget-bg: #{$md-sys-color-light-main-widget-bg};
  --md-sys-color-main-web-background: #{$md-sys-color-light-main-web-background};
  --md-sys-color-main-accent-activated: #{$md-sys-color-light-main-accent-activated};
  --md-sys-color-main-accent-inactivated: #{$md-sys-color-light-main-accent-inactivated};
  --md-sys-color-main-mono: #{$md-sys-color-light-main-white};

  /* light - text */
  --md-sys-color-text-grey: #{$md-sys-color-light-text-grey};
  --md-sys-color-text-common: #{$md-sys-color-light-text-black};
  --md-sys-color-text-main-tes: #{$md-sys-color-light-text-main-tes};
  --md-sys-color-text-black: #{$md-sys-color-light-text-black};
  --md-sys-color-text-white: #{$md-sys-color-dark-text-white};

  /* light - accordion colors */
  --md-sys-color-accordion-colors-pink-light: #{$md-sys-color-light-accordion-colors-pink-light};
  --md-sys-color-accordion-colors-pink-dark: #{$md-sys-color-light-accordion-colors-pink-dark};
  --md-sys-color-accordion-colors-blue-light: #{$md-sys-color-light-accordion-colors-blue-light};
  --md-sys-color-accordion-colors-blue-dark: #{$md-sys-color-light-accordion-colors-blue-dark};
  --md-sys-color-accordion-colors-green-light: #{$md-sys-color-light-accordion-colors-green-light};
  --md-sys-color-accordion-colors-green-dark: #{$md-sys-color-light-accordion-colors-green-dark};
  --md-sys-color-accordion-colors-grey-light: #{$md-sys-color-light-accordion-colors-grey-light};
  --md-sys-color-accordion-colors-grey-dark: #{$md-sys-color-light-accordion-colors-grey-dark};
  --md-sys-color-accordion-colors-yellow-light: #{$md-sys-color-light-accordion-colors-yellow-light};
  --md-sys-color-accordion-colors-yellow-dark: #{$md-sys-color-light-accordion-colors-yellow-dark};

  /* light - gradient */
  --md-sys-color-gradient-tes-main: $md-sys-color-light-gradient-tes-main;
  --md-sys-color-gradient-green: $md-sys-color-light-gradient-green;
  --md-sys-color-gradient-orange: $md-sys-color-light-gradient-orange;
  --md-sys-color-gradient-grey: $md-sys-color-light-gradient-grey;

  /* light - drivers styles text */
  --md-sys-color-driver-text-danger: #{$md-sys-color-light-driver-text-danger};
  --md-sys-color-driver-text-bad: #{$md-sys-color-light-driver-text-bad};
  --md-sys-color-driver-text-good: #{$md-sys-color-light-driver-text-good};
  --md-sys-color-driver-text-perfect: #{$md-sys-color-light-driver-text-perfect};

  /* label - color */
  --md-sys-color-label-green: #{$md-sys-color-label-green};
  --md-sys-color-label-dark-grey: #{$md-sys-color-label-dark-grey};
  --md-sys-color-label-light-grey: #{$md-sys-color-label-light-grey};
  --md-sys-color-label-blue: #{$md-sys-color-label-blue};
  --md-sys-color-label-yellow: #{$md-sys-color-label-yellow};
  --md-sys-color-label-pink: #{$md-sys-color-label-pink};
  --md-sys-color-label-red: #{$md-sys-color-label-red};

  /* shadow */
  --md-sys-color-shadow: #{$md-sys-color-shadow};
  --md-sys-color-shadow-button: #{$md-sys-color-shadow-button};

  &.dark-theme {
    /* dark - main */
    --md-sys-color-main-sidebar: #{$md-sys-color-dark-main-sidebar};
    --md-sys-color-main-sidebar-chosen-point: #{$md-sys-color-dark-main-sidebar-chosen-point};
    --md-sys-color-main-icons: #{$md-sys-color-dark-main-icons};
    --md-sys-color-main-inactive: #{$md-sys-color-dark-main-inactive};
    --md-sys-color-main-input-field: #{$md-sys-color-dark-main-input-field};
    --md-sys-color-main-divider: #{$md-sys-color-dark-main-divider};
    --md-sys-color-main-widget-bg: #{$md-sys-color-dark-main-widget-bg};
    --md-sys-color-main-web-background: #{$md-sys-color-dark-main-web-background};
    --md-sys-color-main-accent-activated: #{$md-sys-color-dark-main-accent-activated};
    --md-sys-color-main-accent-inactivated: #{$md-sys-color-dark-main-accent-inactivated};
    --md-sys-color-main-mono: #{$md-sys-color-dark-main-black};

    /* dark - text */
    --md-sys-color-text-grey: #{$md-sys-color-dark-text-grey};
    --md-sys-color-text-common: #{$md-sys-color-dark-text-white};
    --md-sys-color-text-main-tes: #{$md-sys-color-dark-text-main-tes};
    --md-sys-color-text-black: #{$md-sys-color-light-text-black};
    --md-sys-color-text-white: #{$md-sys-color-dark-text-white};

    /* dark - accordion colors */
    --md-sys-color-accordion-colors-pink-light: #{$md-sys-color-dark-accordion-colors-pink-light};
    --md-sys-color-accordion-colors-pink-dark: #{$md-sys-color-dark-accordion-colors-pink-dark};
    --md-sys-color-accordion-colors-blue-light: #{$md-sys-color-dark-accordion-colors-blue-light};
    --md-sys-color-accordion-colors-blue-dark: #{$md-sys-color-dark-accordion-colors-blue-dark};
    --md-sys-color-accordion-colors-green-light: #{$md-sys-color-dark-accordion-colors-green-light};
    --md-sys-color-accordion-colors-green-dark: #{$md-sys-color-dark-accordion-colors-green-dark};
    --md-sys-color-accordion-colors-grey-light: #{$md-sys-color-dark-accordion-colors-grey-light};
    --md-sys-color-accordion-colors-grey-dark: #{$md-sys-color-dark-accordion-colors-grey-dark};
    --md-sys-color-accordion-colors-yellow-light: #{$md-sys-color-dark-accordion-colors-yellow-light};
    --md-sys-color-accordion-colors-yellow-dark: #{$md-sys-color-dark-accordion-colors-yellow-dark};

    /* dark - gradient */
    --md-sys-color-gradient-tes-main: $md-sys-color-dark-gradient-tes-main;
    --md-sys-color-gradient-green: $md-sys-color-dark-gradient-green;
    --md-sys-color-gradient-orange: $md-sys-color-dark-gradient-orange;
    --md-sys-color-gradient-grey: $md-sys-color-dark-gradient-grey;

    /* dark - drivers styles text */
    --md-sys-color-driver-text-danger: #{$md-sys-color-dark-driver-text-danger};
    --md-sys-color-driver-text-bad: #{$md-sys-color-dark-driver-text-bad};
    --md-sys-color-driver-text-good: #{$md-sys-color-dark-driver-text-good};
    --md-sys-color-driver-text-perfect: #{$md-sys-color-dark-driver-text-perfect};

    /* label - color */
    --md-sys-color-label-green: #{$md-sys-color-label-green};
    --md-sys-color-label-dark-grey: #{$md-sys-color-label-dark-grey};
    --md-sys-color-label-light-grey: #{$md-sys-color-label-light-grey};
    --md-sys-color-label-blue: #{$md-sys-color-label-blue};
    --md-sys-color-label-yellow: #{$md-sys-color-label-yellow};
    --md-sys-color-label-pink: #{$md-sys-color-label-pink};
    --md-sys-color-label-red: #{$md-sys-color-label-red};

    /* shadow */
    --md-sys-color-shadow: $md-sys-color-shadow;
    --md-sys-color-shadow-button: #{$md-sys-color-shadow-button};
  }
}
